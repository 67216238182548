import { retry as _retry } from 'ts-retry-promise';

export const retry: typeof _retry = (callback, options) => {
  return _retry(callback, {
    timeout: 10000,
    retries: 3,
    backoff: 'LINEAR',
    ...options,
  });
};
